import React from "react"
import Layout from "~/components/common/Layout"
import Quiz from "~/components/page/Quiz"
import { getHeaderTitles, getNextQuizLink, getNextTitle, getPreviousQuizLink } from "~/services/pages"
import withProgress from "~/services/withProgress"

const Page = ({ myPage, mySubpage }) => {
  const questionNumber = 3
  const nextLink = getNextQuizLink(myPage, mySubpage, questionNumber)

  return (
    <Layout
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getPreviousQuizLink(myPage, mySubpage, questionNumber)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
      }}
    >
      <Quiz
        quizNumber={"TWO"}
        questionNumber={questionNumber}
        questionsTotal={5}
        question={
          "True or false: All violations of SBCs and non-negotiables should be met with the same consequences."
        }
        answers={["True", "False"]}
        correctAnswerNumber={1}
        answerDetail={
          "Non-negotiables are guidelines that are put in place to prevent the very worst behaviors that can create an unsafe environment for our players. Coaches should always respond the same way to each of these behaviors every time it happens, no matter who, where or why they happen."
        }
        nextLink={nextLink}
      ></Quiz>
    </Layout>
  )
}

export default withProgress(Page, 2, 6)
